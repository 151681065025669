import styled from "styled-components"

import { TUserBillingInfoBillingAddress } from "src/data/billing/types/billingTypes"
import { countryCodeToCountry } from "src/data/countries/countryUtil"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { backgroundGrayV2, recommendedGray } from "src/ui/colors"
import { Divider } from "src/ui/Divider/Divider"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function PrintedAddress({
  vatNumber,
  billingAddress,
}: {
  vatNumber: string | undefined
  billingAddress: TUserBillingInfoBillingAddress | undefined
}) {
  const { t } = useTranslate()

  if (!billingAddress) {
    return null
  }

  return (
    <Box style={{ color: recommendedGray }}>
      <Row value={<b>{billingAddress?.first_name}</b>}>
        {billingAddress?.last_name && <b>{` ${billingAddress?.last_name}`}</b>}
      </Row>
      <Row value={billingAddress?.company} />
      <Row value={billingAddress?.email} />
      <Row value={billingAddress?.phone} />
      <Row value={billingAddress?.line1} />
      <Row value={billingAddress?.line2} />
      {postline(billingAddress)}

      {vatNumber && (
        <div>
          <Divider $margin={spacing.S} />
          <MText variant="bodyS" color="secondary" as="span">
            {t(langKeys.billing_vat)}:
          </MText>{" "}
          {vatNumber}
        </div>
      )}
    </Box>
  )
}

function postline(billingAddress?: TUserBillingInfoBillingAddress) {
  const country =
    billingAddress?.country && countryCodeToCountry(billingAddress.country)

  const values = [
    billingAddress?.zip,
    billingAddress?.city,
    billingAddress?.state_code,
    country,
  ]
    .filter(Boolean)
    .filter((i) => i && i.length > 0)

  return values.join(", ")
}

const Box = styled.div`
  display: grid;
  background: ${backgroundGrayV2};
  padding: ${spacing.M} ${spacing.L};
  border-radius: 14px;
`

function Row({
  value,
  children,
}: {
  value?: string | React.ReactNode
  children?: React.ReactNode
}) {
  if (!value) {
    return null
  }

  return (
    <div>
      {value}
      {children}
    </div>
  )
}

export function PrintedAddressSummary({
  vatNumber,
  billingAddress,
}: {
  vatNumber: string | undefined
  billingAddress: TUserBillingInfoBillingAddress | undefined
}) {
  const { t, langKeys } = useTranslate()

  if (!billingAddress) {
    return null
  }

  const items = [
    billingAddress?.first_name,
    billingAddress.last_name,
    billingAddress.company,
    billingAddress.line1,
    billingAddress.line2,
    postline(billingAddress),
    vatNumber ? `${t(langKeys.billing_vat)}: ${vatNumber}` : undefined,
  ].filter(Boolean)
  const itemString = items.join(", ")

  return <div>{itemString}</div>
}
